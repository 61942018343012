<template>
    <div class="home">
        <top-user-bar
                :userPhoto="$store.getters.userinfo.avatar"
                :userName="$store.getters.userinfo.nickName"
                :city="$store.getters.city.name"
        ></top-user-bar>

        <!-- 列表下拉刷新 -->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" direction="up" style="margin-bottom: 80px">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <product-card v-for="(item,index) in list" v-bind="item" :key='index'/>
            </van-list>
        </van-pull-refresh>
        <!-- 底部导航 -->
        <bottom-bar/>

    </div>
</template>

<script>
  // @ is an alias to /src
  import TopUserBar from "@/components/TopUserBar.vue";
  import BottomBar from "@/components/BottomBar.vue";
  import ProductCard from "@/components/ProductCard.vue";
  import {getGoodsList} from "@/api/goods";

  export default {
    name: "expproducts",
    data () {
      return {
        userInfo: {
          photoUrl: require("@/assets/defaultuserpic.png"),
          userName: "小丸子",
          city: "北京站",
        },

        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 1,
        pages: 1,
        list: []
      }
    },
    methods: {
      loadGoodsList () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        this.loading = true;
        getGoodsList({
          special: 3,
          siteId: this.$store.getters.city.id,
          pageNum: this.pageNum
        }).then(data => {
          if (data.success == true) {
            this.pageNum = data.data.current + 1;
            this.pages = data.data.pages;
            this.list.push(...this.handleData(data.data.records));
          }
        }).finally(() => {
          this.loading = false
        })
      },
      handleData (data) {
        const res = [];
        data.forEach(item => {
          res.push({
            id: item.productId,
            picUrl: item.productCoverImg,
            productTitle: item.title,
            leftTip: true,
            leftTipTxt: item.isSellOut ? '已售罄' : '抢购中',
            rightTip: true,
            timer: item.endTime,
            FreeShipping: true,
            realPrice: parseFloat(item.price),
            oldPrice: parseFloat(item.marketPrice),
            backPrice: parseFloat(item.commission),
            sales: parseInt(item.showSales),
          })
        });
        return res
      },
      onSearch () {

      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.refreshing = false;
        }
        this.loadGoodsList()
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
    },
    created () {
      this.loadGoodsList()
    },
    components: {
      "top-user-bar": TopUserBar,
      'product-card': ProductCard,
      'bottom-bar': BottomBar
    },
  };
</script>
